<template>
  <span>
    <a :href="targetURL" target="_blank">
      <span
        v-for="(letterObject, index) in formattedArrayOfLetterObjects"
        v-bind:key="index"
        v-bind:class="letterObject.letterClass"
        >{{ letterObject.oneletter }}</span
      >
      <span class="customSubscript">
        {{ wordScore }}
      </span>
    </a>
  </span>
</template>

<script>
export default {
  props: {
    word: Object
  },
  computed: {
    targetURL: function() {
      return "https://www.dictionary.com/browse/" + this.getWordString();
    },

    arrayOfLetters: function() {
      return this.word.letters;
    },
    formattedArrayOfLetterObjects: function() {
      var arrayOfLetterObjects = [];

      var privateArrayOfLetters = this.word.letters;

      for (var f = 0; f < privateArrayOfLetters.length; f += 2) {
        var oneLetter = {
          oneletter: this.word.letters[f].toLowerCase(),
          letterClass: this.word.letters[f + 1]
        };
        arrayOfLetterObjects.push(oneLetter);
      }
      return arrayOfLetterObjects;
    },
    wordScore: function() {
      return this.word.score;
    }
  },
  methods: {
    getWordString() {
      var arrayOfLetters = [];
      var privateArrayOfLetters = this.word.letters;

      for (var f = 0; f < privateArrayOfLetters.length; f += 2) {
        var oneLetter = this.word.letters[f].toLowerCase();
        arrayOfLetters.push(oneLetter);
      }
      var formattedWord = arrayOfLetters.join("");
      return formattedWord;
    }
  }
};
//color: rgb(161, 98, 20);
</script>

<style scoped>
.N {
  color: rgb(209, 118, 8);
}
.W {
  color: #649ecb;
}

.customSubscript {
  position: relative;
  top: 0.3em;
  color: gray;
  font-size: 0.6em;
}
</style>
