import axios from "axios";
import packageRequestService from "./packageRequestService";

export default {
  findWords(apiDataTransmit) {
    const urlString =
      "https://techmarklabs.tech/" + "getWords/" + apiDataTransmit;
    let token = packageRequestService.packageRequest();
    return axios.get(urlString, {
      headers: {
        Authorization: token
      }
    });
  },

  findQWords() {
    const urlString = "https://techmarklabs.tech/" + "getAllQWords";
    let token = packageRequestService.packageRequest();
    return axios.get(urlString, {
      headers: {
        Authorization: token
      }
    });
  },

  findJWords() {
    const urlString = "https://techmarklabs.tech/" + "getAllJWords";
    let token = packageRequestService.packageRequest();
    return axios.get(urlString, {
      headers: {
        Authorization: token
      }
    });
  },

  findZWords() {
    const urlString = "https://techmarklabs.tech/" + "getAllZWords";
    let token = packageRequestService.packageRequest();
    return axios.get(urlString, {
      headers: {
        Authorization: token
      }
    });
  },

  findTwoLetterWords() {
    const urlString = "https://techmarklabs.tech/" + "getAllTwoLetterWords";
    let token = packageRequestService.packageRequest();
    return axios.get(urlString, {
      headers: {
        Authorization: token
      }
    });
  },

  findThreeLetterWords() {
    const urlString = "https://techmarklabs.tech/" + "getAllThreeLetterWords";
    let token = packageRequestService.packageRequest();
    return axios.get(urlString, {
      headers: {
        Authorization: token
      }
    });
  }
};
